import { Button, IconArrowRight } from 'hds-react';
import Playground from "../../../../src/components/Playground";
import TabsLayout from "../../../../src/docs/services/seree/tabs.mdx";
import * as React from 'react';
export default {
  Button,
  IconArrowRight,
  Playground,
  TabsLayout,
  React
};